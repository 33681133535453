import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import FlexibleLink from '../../ui/FlexibleLink';
import { ILink } from '../../ui/FlexibleLink/type';
import Headline from '../../ui/Headline';
import Image from '../../ui/Image';
import { IImage } from '../../ui/Image/types';
import * as styles from './styles.module.scss';

type FullwidthImageTeaserProps = {
  bgImage: IImage;
  headline: string;
  subHeadline: string;
  link: ILink;
};

const FullwidthImageTeaser: React.FC<FullwidthImageTeaserProps> = ({ bgImage, headline, subHeadline, link }) => {
  const { width } = useWindowDimensions();

  return (
    <div
      className={headline === null && subHeadline === null && link === null ? styles.container : styles.containerTeaser}
    >
      {bgImage && <Image {...bgImage} additionalClass={styles.img} />}
      {headline !== null && (
        <div className={styles.wrapper}>
          <Parallax y={[-20, 20]} disabled={width < 640}>
            {subHeadline !== null && <p className={styles.subHeadline}>{subHeadline}</p>}
            <Headline level={1} color="White" additionalClass={styles.hl}>
              {headline}
            </Headline>
            {link && <FlexibleLink link={link} />}
          </Parallax>
        </div>
      )}
    </div>
  );
};

export default FullwidthImageTeaser;
